import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Navbar extends Component {
  state = {
    navActive: false
  };

  toggleMenu = () => {
    this.setState({ navActive: !this.state.navActive }, () => {});
  };

  render() {
    return (
      <div className="nav-wrapper">
        <span
          className={
            'text-uppercase nav-open px-4 py-3 ' +
            (this.state.navActive ? 'd-none' : 'd-block')
          }
          onClick={this.toggleMenu}
        >
          Menu
        </span>
        <span
          className={
            'text-uppercase nav-close px-4 py-3 ' +
            (this.state.navActive ? 'd-block' : 'd-none')
          }
          onClick={this.toggleMenu}
        >
          Close
        </span>

        <div
          className={
            'd-flex justify-content-between px-4 py-3 main-nav ' +
            (this.state.navActive ? 'active' : 'inactive')
          }
        >
          <div className="mx-auto my-auto">
            <NavLink to="/" onClick={this.toggleMenu} className="menu-link">
              <p>Home</p>
            </NavLink>
            <NavLink to="/work" onClick={this.toggleMenu} className="menu-link">
              <p>Work</p>
            </NavLink>
            {/* <NavLink
              to="/about"
              onClick={this.toggleMenu}
              className="menu-link"
            >
              <p>About</p>
            </NavLink> */}
            <NavLink
              to="/contact"
              onClick={this.toggleMenu}
              className="menu-link"
            >
              <p>Contact</p>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
