import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div className="container mb-4">
        <div className="col-12 text-center">
          <h1 className="serif mb-4">Contact</h1>
        </div>
        <div className="row">
          <div className="col-md-5 d-flex align-items-center justify-content-center contact-image-wrapper">
            <img src="https://kathryn-goshorn-assets.s3.amazonaws.com/hand_a_contact.jpg" />
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="contact-info-wrapper">
              <a href="mailto:kmgoshorn@gmail.com">
                <div className="contact-row">
                  <p className="contact-type">General inquiries</p>
                  <p className="contact-info">kmgoshorn@gmail.com</p>
                </div>
              </a>
              <a
                href="https://www.instagram.com/kathryngoshorn/"
                target="_blank"
              >
                <div className="contact-row">
                  <p className="contact-type">Instagram</p>
                  <p className="contact-info">@kathryngoshorn</p>
                </div>
              </a>
            </div>
            <p className="contact-note">
              Inquiries, comments, follows, donations,
              and movie recommendations all welcome.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
