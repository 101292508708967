import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import './Home.scss';

class Home extends Component {
  state = {
    ctaActive: true
  };

  scrolledDown = () => {
    const el = document.getElementById('first-section');
    if (el.getBoundingClientRect().bottom <= window.innerHeight) {
      this.setState({
        ctaActive: false
      });
      document.removeEventListener('scroll', this.scrolledDown);
    }
  };

  componentDidMount() {
    document.addEventListener('scroll', this.scrolledDown);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrolledDown);
  }

  render() {
    return (
      <div>
        <div className="kathryn-hero">
          <img
            className="active"
            src="https://kathryn-goshorn-assets.s3.amazonaws.com/reclining_nude_transparent.png"
            alt=""
          />
          <h1>Kathryn Goshorn</h1>
          <h2>Brooklyn-based artist</h2>
          <NavLink
            to="/work"
            className={
              'text-uppercase px-4 py-3 see-work ' +
              (this.state.ctaActive ? 'd-block' : 'fade-away')
            }
          >
            See all work
          </NavLink>
        </div>
        <div className="container px-0">
          <div id="first-section" className="row mb-2 mx-0">
            <div className="col-md-6 mb-4 px-0 recent-image-wrapper">
              <img
                className="img-fluid"
                src="https://kathryn-goshorn-assets.s3.amazonaws.com/cat_bite_b.jpg"
                alt=""
              />
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center recent-work">
              <h3>Recent work</h3>
              <h2 className="serif mb-4">Cat Bite</h2>
              <p>Egg tempera on sculpted plaster</p>

              <Link to="/work">
                <h4>See all work</h4>
              </Link>
            </div>
          </div>
          <div id="second-section" className="row mb-5 mx-0">
            <div className="col-md-6 mb-4 px-0 recent-image-wrapper">
              <img
                className="img-fluid w-100"
                src="https://kathryn-goshorn-assets.s3.amazonaws.com/climate_clocks_a.jpg"
                alt=""
              />
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center recent-work">
              <h3>Recent work</h3>
              <h2 className="serif mb-4">Climate Clocks</h2>
              <p>4’x80’x9”</p>
              <p>Acrylic on rolls of paper</p>
              <p>
                In collaboration with Janet Zweig, commisioned by the city of
                San Diego, CA.
              </p>
              <Link to="/work">
                <h4>See all work</h4>
              </Link>
            </div>
          </div>
        </div>
        <div className="kathryn-home-background">
          <div className="col-6"></div>
          <div className="col-md-6 kathryn-home-info">
            <h2 className="serif">Get in touch</h2>
            {/* <Link to="/about">
              <p>About</p>
            </Link> */}
            <Link to="/contact">
              <h4>Contact</h4>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    works: state.artwork
  };
};

export default connect(mapStateToProps)(Home);
