import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <>
        <div className="container mb-4">
          <div className="row">
            <div className="col-10 col-md-6 mx-auto text-center">
              <h1 className="serif mb-4">About Kathryn</h1>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                vitae fringilla elit. Suspendisse potenti. Vivamus odio mauris,
                dictum lobortis blandit eu, cursus sit amet dui.
              </p> */}
            </div>
          </div>
        </div>
        {/* <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-6 kathryn-about-background mb-4"></div>
            <div className="col-md-6 d-flex flex-column justify-content-center kathryn-about-info">
          <p>
          Hunt anything that moves. Meowing chowing and wowing mewl for
          food at 4am so there's a forty year old lady there let us feast.
          Immediately regret falling into bathtub my left donut is
          missing, as is my right or poop in litter box, scratch the
          walls. Crusty butthole fall over dead (not really but gets
          sypathy) so gnaw the corn cob use lap as chair. Have secret
          plans.
          </p>
            </div>
          </div>
        </div> */}
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <h2 className="serif text-center">Exhibitions</h2>
              <div className="exhibition-row header my-4">
                <p className="exhibition-name">Exhibition</p>
                <p className="exhibition-gallery">Gallery</p>
                <p className="exhibition-year">Year</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">Flat Earth Conspiracy</p>
                <p className="exhibition-gallery">George Adams Gallery</p>
                <p className="exhibition-year">2017</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">Chubb Fellows Exhibition</p>
                <p className="exhibition-gallery">New York Academy of Art</p>
                <p className="exhibition-year">2016</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">Tribeca Ball</p>
                <p className="exhibition-gallery">New York Academy of Art</p>
                <p className="exhibition-year">2015</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">MFA Open Studios</p>
                <p className="exhibition-gallery">New York Academy of Art</p>
                <p className="exhibition-year">2015</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">Deck the Walls</p>
                <p className="exhibition-gallery">New York Academy of Art</p>
                <p className="exhibition-year">2014</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">Take Home A Nude</p>
                <p className="exhibition-gallery">Sotheby’s</p>
                <p className="exhibition-year">2014</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">Tribeca Ball</p>
                <p className="exhibition-gallery">New York Academy of Art</p>
                <p className="exhibition-year">2014</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">Deck the Walls</p>
                <p className="exhibition-gallery">New York Academy of Art</p>
                <p className="exhibition-year">2013</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">
                  Georgia Artists Selecting Georgia Artists
                </p>
                <p className="exhibition-gallery">
                  Museum of Contemporary Art of Georgia
                </p>
                <p className="exhibition-year">2013</p>
              </div>
              <div className="exhibition-row">
                <p className="exhibition-name">
                  26th Annual Juried Printmaking & Book Arts Exhibition
                </p>
                <p className="exhibition-gallery">University of Georgia</p>
                <p className="exhibition-year">2013</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
