import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Layout from './components/layout/Layout';

import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './components/Home';
import About from './components/About';
import WorkShow from './components/works/Show';
import WorkIndex from './components/works/Index';
import Contact from './components/Contact';



const App = (props) => {
  useEffect(() => {
    props.populateRecentlyViewed();
  }, [props.populateRecentlyViewed]);

  return (
    <Router>
      <Layout>
        <>
          <Route 
            render={() => {
              window.scrollTo(0 ,0);
              return null;
            }} 
          />
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route path="/about" component={About} /> */}
            <Route
              exact
              path="/work/:id"
              render={props => <WorkShow {...props} />}
            />
            <Route path="/work" render={props => <WorkIndex {...props} />} />
            <Route path="/contact" component={Contact} />
          </Switch>
        </>
      </Layout>
    </Router>
  );
};

App.propTypes = {
  populateRecentlyViewed: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    populateRecentlyViewed: () => dispatch({ type: 'POPULATE_RECENTLY_VIEWED' })
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);
