import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateViewMore } from '../../store/reducer';

import WorkItem from './WorkItem';

class WorkShow extends Component {
  state = {
    work: {}
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let work = nextProps.works.find(a => {
      return nextProps.match.params.id === a.id.toString();
    });
    
    if (!work) {
      work = nextProps.miscWorks.find(a => {
        return nextProps.match.params.id === a.id.toString();
      });
    }
    
    if (nextProps.match.params.id !== prevState.work.id) {
      nextProps.updateViewMore(work, prevState.work);
    }
    return {
      work: work
    };
  };

  render() {
    return (
      <>
        <div className="work-top-row mb-4">
          <div className="col-12 col-md-6 work-info mb-4">
            <h1>{this.state.work.title}</h1>
            <p
              className={this.state.work.subtitle ? 'work-subtitle' : 'd-none'}
            >
              {this.state.work.subtitle || 'subtitle'}
            </p>
            <p className={this.state.work.size ? '' : 'd-none'}>
              {this.state.work.size || 'Size'}
            </p>
            <p>{this.state.work.medium || 'Medium'}</p>
            <p>{this.state.work.year || '2019'}</p>
            <p>{this.state.work.location || 'Location'}</p>
          </div>
          <div className="col-12 col-md-6 px-0 work-main-image">
            <img className="img-fluid" src={this.state.work.images[0]} alt="" />
          </div>
        </div>
        {this.state.work.longImage ? (
          <div
            className={
              'container-fluid my-5 px-0 ' +
              (this.state.work.images.length === 1 ? 'd-none' : '')
            }
          >
            <div
              className="row full-image-container mx-0"
              style={{
                backgroundImage: 'url("' + this.state.work.images[1] + '")',
                backgroundSize: 'cover',
                backgroundPosition: 'center center'
              }}
            ></div>
          </div>
        ) : null}
        <div className="container px-0">
          <div className="row mx-0">
            <div className="col-10 col-md-8 mx-auto ">
              <p className={this.state.work.description ? 'mb-5' : 'd-none'}>
                {this.state.work.description}
              </p>
            </div>
            <div
              className={
                'col-md-8 text-center mx-auto px-0 work-images' +
                (this.state.work.images.length === 1 ? 'd-none' : '')
              }
            >
              {this.state.work.longImage ? null : (
                <img
                  className="img-fluid mb-4"
                  src={this.state.work.images[1]}
                  alt=""
                />
              )}
              <img
                className="img-fluid mb-4"
                src={this.state.work.images[2]}
                alt=""
              />
              <img
                className="img-fluid mb-4"
                src={this.state.work.images[3]}
                alt=""
              />
              <img
                className="img-fluid mb-4"
                src={this.state.work.images[4]}
                alt=""
              />
            </div>
          </div>
          <div className="row mx-0">
            <h4 className="serif mx-auto">See more work</h4>
            <ul className="portfolio see-more">
              {this.props.viewMore.length > 0
                ? this.props.viewMore.map(art => (
                    <WorkItem
                      key={art.images[0]}
                      id={art.id}
                      images={art.images}
                      description={art.description}
                    />
                  ))
                : null}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    works: state.works,
    miscWorks: state.miscWorks,
    viewMore: state.viewMore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateViewMore: (currentlyViewing, lastViewed) =>
      dispatch(updateViewMore(currentlyViewing, lastViewed))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkShow);
