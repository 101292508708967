import React from 'react';
import PropTypes from 'prop-types';

import Navbar from '../Navbar';

const Layout = (props) => (
  <>
    <div className="page-wrapper">
      <Navbar />
      <main className="pt-5">{props.children}</main>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 footer">
          <p>© Kathryn Goshorn 2019</p>
          <p>Designed by Andrew Young</p>
        </div>
      </div>
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.element
}

export default Layout;
