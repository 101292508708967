const works = [
  {
    id: 'climate-clocks',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/climate_clocks_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/climate_clocks_e.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/climate_clocks_b.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/climate_clocks_c.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/climate_clocks_d.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/climate_clocks_e.jpg'
    ],
    title: 'Climate Clocks',
    subtitle: 'In collaboration with Janet Zweig',
    size: '4’x80’x9”',
    medium: 'Acrylic on rolls of paper',
    location: 'San Diego, CA',
    description: '',
    year: '2018'
  },
  {
    id: 'hand-study',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/hand_a.jpg'],
    title: 'Hand Study',
    subtitle: 'In progress',
    medium: 'Egg Tempera on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '5"x7"',
    year: '2017'
  },
  {
    id: 'fish-market',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/fish_market_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/fish_market_b.jpg'
    ],
    title: 'Fish Market',
    size: '20”x21”',
    subtitle: '',
    medium: 'Acrylic on sculpted plaster',
    location: 'Brooklyn, NY',
    description: '',
    year: '2019'
  },
  {
    id: 'cat-bite-ii',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/cat_bite_ii_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/cat_bite_ii_b.jpg'
    ],
    title: 'Cat Bite II',
    size: '12"x12.25"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'mexican-tabletop-with-swollen-knuckle',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/mexican_tabletop_with_swollen_knuckle_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/mexican_tabletop_with_swollen_knuckle_b.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/mexican_tabletop_with_swollen_knuckle_c.jpg'
    ],
    title: 'Mexican Tabletop with Swollen Knuckle',
    size: '24”x24”',
    medium: 'Acrylic and Henry Feather Finish on carved birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2016'
  },
  {
    id: 'diving-sticks',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/diving_sticks_a.jpg'
    ],
    title: 'Diving Sticks',
    size: '24"x24"',
    medium: 'Acrylic, calcium carbonate, epoxy, and Henry Feather Finish on carved birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2016'
  },
  {
    id: 'swimmer',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/swimmer_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/swimmer_b.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/swimmer_c.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/swimmer_d.jpg'
    ],
    title: 'Swimmer',
    size: '24"x24"',
    medium:
      'Acrylic, oil, caulk, resin, and Henry Feather Finish on carved birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2016'
  },
  {
    id: 'man-fishing',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/man_fishing_a.jpg'
    ],
    title: 'Man Fishing',
    size: '24"x32"',
    medium: 'Acrylic, oil, caulk, resin, and Henry Feather Finish on carved birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2015'
  },
  {
    id: 'man-in-bathtub',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/man_in_bathtub_a.jpg'
    ],
    title: 'Man in Bathtub',
    size: '24"x30"',
    medium: 'Henry Feather Finish, oil, and acrylic paint on plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2015'
  },
  {
    id: 'leaves-on-sidewalk',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/leaves_on_sidewalk_a.jpg'
    ],
    title: 'Leaves on Sidewalk',
    size: '24"x30"',
    medium: 'Acrylic and Henry Feather Finish on carved birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2015'
  },
  {
    id: 'portrait-with-other',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/portrait_with_other_a.jpg'
    ],
    title: 'Portrait with Other',
    size: '18”x24”',
    medium: 'Acrylic on birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2014'
  },
  {
    id: 'portrait-in-landscape',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/portrait_in_landscape_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/portrait_in_landscape_b.jpg'
    ],
    title: 'Portrait in Landscape',
    size: '36”x48”',
    medium: 'Acrylic, oil, and cement on birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2014'
  },
  {
    id: 'portrait-with-still_life',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/portrait_with_still_life_a.jpg'
    ],
    title: 'Portrait with Still Life',
    size: '18”x24”',
    medium: 'Acrylic, oil, and cement on birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2014'
  },
  {
    id: 'reclining-nude',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/reclining_nude_a.jpg'
    ],
    title: 'Reclining Nude',
    size: '5"x5"',
    medium: 'Acrylic on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2011'
  },
  {
    id: 'finger-slip',
    images: [''
    ],
    title: 'Finger Slip',
    size: '12"x12.25"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2020'
  },
  {
    id: 'dog-bit-shell-sink',
    images: [''
    ],
    title: 'Dog Bite, Shell Sink',
    size: '19.75"x20"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'iodine-and-two-postcards',
    images: [''
    ],
    title: 'Iodine and Two Postcards',
    size: '19.75"x20"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'arizona-grapefruit',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/arizona_grapefruit_a.jpg',
    ],
    title: 'Arizona Grapefruit',
    size: '7.5"x7"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'first-fresco',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/first_fresco_a.jpg',
    ],
    title: 'First Fresco',
    size: '5.25"x5"',
    medium: 'Pigment on plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'iowa-65',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/iowa_65_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/iowa_65_b.jpg',
    ],
    title: 'Iowa \'65',
    size: '20"x20"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'dog-bite',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/dog_bite_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/dog_bite_b.jpg',
    ],
    title: 'Dog Bite',
    size: '20"x20"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  }
];

const miscWorks = [
  {
    id: 'lost-little-finger-soft-sculpture',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/lost_little_finger_a.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/lost_little_finger_b.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/lost_little_finger_c.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/lost_little_finger_d.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/lost_little_finger_e.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/lost_little_finger_f.jpg',
    ],
    title: 'Lost Little Finger Soft Sculpture',
    size: '20"x17"x6.5"',
    medium: 'Urethane foam, cotton fabric, acrylic paint, embroidery floss',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'iowa-65-study',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/iowa_65_study_a.jpg'],
    title: 'Iowa \'65 study',
    size: '8"x8"',
    medium: 'Acrylic on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'iodine-and-two-postcards-study',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/iodine_and_two_postcards_study_a.jpg'],
    title: 'Iodine and Two Postcards Study',
    size: '9"x12"',
    medium: 'Acrylic on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'a-healing-plant',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/a_healing_plant_a.jpg'],
    title: 'A Healing Plant',
    size: '8"x8"',
    medium: 'Ink on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'jade-and-three-toes',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/jade_and_three_toes_a.jpg'],
    title: 'Jade and Three Toes',
    size: '5"x5"',
    medium: 'Embroidery floss and quilted cotton fabric dyed with acrylic paint',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: '04-21-21',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/04-21-21_a.jpg'],
    title: '04.21.21',
    size: '10.5"x10.75"',
    medium: 'Hand quilted cotton fabric and embroidery floss',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'finger-platter',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/finger_platter_a.jpg'],
    title: 'Finger Platter',
    size: '5.5"x6.75"',
    medium: 'Embroidery floss and quilted cotton fabric dyed with acrylic paint',
    location: 'Brooklyn, NY',
    description: '',
    year: '2020'
  },
  {
    id: 'missing-toenail',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/missing_toenail_a.jpg'],
    title: 'Missing Toenail',
    size: '4.75"x4.5"',
    medium: 'Embroidery floss and quilted cotton fabric dyed with acrylic paint',
    location: 'Brooklyn, NY',
    description: '',
    year: '2020'
  },
  {
    id: 'daddy-issues',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/daddy_issues_a.jpg'],
    title: 'Daddy Issues',
    size: '5"x5"',
    medium: 'Embroidery floss and quilted cotton',
    location: 'Brooklyn, NY',
    description: '',
    year: '2020'
  },
  {
    id: 'turtle-replica',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/turtle_replica.jpg'],
    title: 'Turtle Replica',
    size: '17"x18"x9"',
    medium: 'Acrylic paint, paper mache, cat whiskers, polymer clay, resin',
    location: 'Brooklyn, NY',
    description: '',
    year: '2020'
  },
  {
    id: 'mcgolrick-park-ii',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/mcgorlick_park_ii_a.jpg'],
    title: 'McGolrick Park II',
    size: '5"x7"',
    medium: 'Pen on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'mcgolrick-park',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/mcgorlick_park_a.jpg'],
    title: 'McGolrick Park',
    size: '5"x7"',
    medium: 'Pen on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'london-plane-tree',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/london_plane_tree_a.jpg'],
    title: 'London Plane Tree',
    size: '5.25"x7"',
    medium: 'Pen on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'more-weight',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/more_weight_a.jpg'],
    title: 'More Weight',
    size: '5"x7"',
    medium: 'Ink on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'port-or-storm',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/port_or_storm_a.jpg'],
    title: 'Port or Storm',
    size: '8"x8"',
    medium: 'Pen and ink on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'cactus-study',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/cactus_study_a.jpg'],
    title: 'Cactus Study',
    size: '5"x7"',
    medium: 'Ink on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'assisted-suicide',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/assisted_suicide_a.jpg'],
    title: 'Assisted Suicide',
    size: '5"x7"',
    medium: 'Ink on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'spitting-dolphin',
    images: ['https://kathryn-goshorn-assets.s3.amazonaws.com/spitting_dolphin_a.jpg'],
    title: 'Spitting Dolphin',
    size: '8"x8"',
    medium: 'Ink on paper',
    location: 'Brooklyn, NY',
    description: '',
    year: '2021'
  },
  {
    id: 'cat-bite',
    images: [
      'https://kathryn-goshorn-assets.s3.amazonaws.com/cat_bite_b.jpg',
      'https://kathryn-goshorn-assets.s3.amazonaws.com/cat_bite_c.jpg'
    ],
    title: 'Cat Bite',
    size: '6.75"x6"',
    medium: 'Acrylic on sculpted plaster mounted to birch plywood',
    location: 'Brooklyn, NY',
    description: '',
    year: '2017'
  },
];

export { works, miscWorks };
