import React from 'react';
import { Link } from 'react-router-dom';

const artwork = props => {
  return (
    <li>
      <Link
        to={{
          pathname: '/work/' + props.id
        }}
        className="work-thumbnail"
      >
        <img src={props.images[0]} alt="" />
      </Link>
    </li>
  );
};

export default artwork;
