import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import WorkItem from './WorkItem';

class WorkIndex extends Component {
  work = this.props.works.map(art => (
    <WorkItem
      key={art.id}
      id={art.id}
      images={art.images}
      description={art.description}
      click={this.alertStuff}
    />
  ));

  miscWork = this.props.miscWorks.map(art => (
    <WorkItem
      key={art.id}
      id={art.id}
      images={art.images}
      description={art.description}
      click={this.alertStuff}
    />
  ));

  render() {
    return (
      <>
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center serif">
              <h2>Paintings</h2>
              <ul className="portfolio">{this.work}</ul>
              <h2>Misc</h2>
              <ul className="portfolio">{this.miscWork}</ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

WorkIndex.propTypes = {
  works: PropTypes.array,
  miscWorks: PropTypes.array
};

const mapStateToProps = state => {
  return {
    works: state.works,
    miscWorks: state.miscWorks
  };
};

export default connect(mapStateToProps)(WorkIndex);
