import { works, miscWorks } from './works';

const initialState = {
  works: works,
  miscWorks: miscWorks,
  sortedByViewed: [],
  viewMore: [],
  currentlyViewing: {}
};

export const ActionTypes = {
  POPULATE_RECENTLY_VIEWED: 'POPULATE_RECENTLY_VIEWED',
  UPDATE_VIEW_MORE: 'UPDATE_VIEW_MORE',
};

export const updateViewMore = (currentlyViewing, lastViewed) => ({
  type: ActionTypes.UPDATE_VIEW_MORE,
  payload: {
    currentlyViewing,
    lastViewed
  }
});

const VIEW_MORE_LENGTH = 4;

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.POPULATE_RECENTLY_VIEWED: {
      const sortedByViewed = [...state.works, ...state.miscWorks];
      const viewMore = sortedByViewed.slice(0, VIEW_MORE_LENGTH);
  
      return {
        ...state,
        sortedByViewed,
        viewMore
      };
    }
    case ActionTypes.UPDATE_VIEW_MORE: {
      const { currentlyViewing, lastViewed } = payload;

      const sortedByViewed = [...state.sortedByViewed].filter(({ id }) => {
        if (lastViewed && lastViewed.id) {
          return id !== currentlyViewing.id && id !== lastViewed.id;
        } else if (currentlyViewing) {
          return id !== currentlyViewing.id;
        }
      }).concat(lastViewed.id ? [currentlyViewing, lastViewed] : [currentlyViewing]);

      const sliceLength = lastViewed.id ? VIEW_MORE_LENGTH - 1 : VIEW_MORE_LENGTH;

      const viewMore = [...sortedByViewed]
        .slice(0, sliceLength)
        .concat(lastViewed.id ? [lastViewed] : []);

      return {
        ...state,
        sortedByViewed,
        viewMore
      };
    }
    default:
      return state;
  }
};

export default reducer;
